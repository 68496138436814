<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <img
        src="/logo.png"
        alt="Tecnitex"
        class=""
        width="180"
      >
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Página no encontrada
        </h2>
        <p class="mb-2">
          La página que estabas buscando no se ha encontrado, puede deberse a un error <br> de texto o a que la página ya no existe
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="getHomePath()"
        >
          HOME
        </b-button>

        <!-- image -->
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import {mapGetters} from "vuex";

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
    ...mapGetters({
      currentUser: 'auth/getUser',
    }),
  },
  methods: {
    getHomePath() {
      console.log(this.currentUser)
      const roleName = this.currentUser.roles[0].name

      switch (roleName) {
        case 'super_admin':
        case 'admin':
        case 'user':
          return { path: '/admin/orders' }
        case 'documental':
          return { path: '/categories/list' }
        case 'tec':
        case 'tec_client':
          return { path: '/assets' }
        default:
          // En caso de que el rol no coincida con ninguno de los casos anteriores,
          // podrías manejarlo de alguna manera, como redirigir a una página de inicio predeterminada.
          return { path: '/admin' }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
